<template>
  <v-app>
    <v-main>
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </v-main>
    <BottomBar class="fixed-bottom"></BottomBar>
  </v-app>
</template>

<script>
import BottomBar from './components/content/BottomBar'
export default {
  name: 'App',

  components: {
    BottomBar
  },

  data: () => ({
    //
  }),
  mounted() {
  }
}
</script>
<style scoped>
.fixed-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>