import { request } from './request'

export function getProfile() {
  return request({
    url: '/user/profile',
  })
}

export function postProfile(nickname, phone) {
  const data = {
    nickname,
    phone
  }
  return request({
    method: 'POST',
    url: '/user/profile',
    data
  })
}

export function bindStu(username, password) {
  const data = {
    username,
    password
  }
  return request({
    method: 'POST',
    url: '/user/bind_stu',
    data
  })
}

export function bindInfo(term_start, room_id = '') {
  const data = {
    term_start,
    room_id
  }
  return request({
    method: 'POST',
    url: '/user/bind_stu',
    data
  })
}
