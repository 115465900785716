import Vue from 'vue'
import VueRouter from 'vue-router'
import checkStorage from 'common/storage'
import store from '../store'
const Profile = () => import('views/profile/Profile')
const Today = () => import('views/today/Today')
const About = () => import('views/profile/sets/About')
const CardSet = () => import('views/profile/sets/CardSet')
const UpdateLog = () => import('views/profile/sets/UpdateLog')
const FeeBack = () => import('views/profile/sets/FeeBack')
const Sponsor = () => import('views/profile/sets/Sponsor')
const UpdateProfile = () => import('views/profile/sets/UpdateProfile')
const Login = () => import('components/content/Login')
const Bind = () => import('views/today/bind/Bind')
const Privacy = () => import('views/today/bind/Privacy')
const GetSchedule = () => import('views/today/features/GetSchedule')
const GetPower = () => import('views/today/features/GetPower')

// 避免页面路由重复点击报错
const originalPush = VueRouter.prototype.push
   VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/today',
    name: 'today',
    component: Today,
    meta: { requiresAuth: true },
    children: [
      {
        path: 'bind',
        name: 'bind',
        component: Bind
      },
      {
        path: 'privacy',
        name: 'privacy',
        component: Privacy
      },
      {
        path: 'getSchedule',
        name: 'InfoBind',
        component: GetSchedule
      },
      {
        path: 'getpower',
        name: 'GetPower',
        component: GetPower
      }
    ]
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: { requiresAuth: true },
    children: [
      {
        path: 'about',
        component: About,
      },
      {
        path: 'cardset',
        component: CardSet,
      },
      {
        path: 'updatelog',
        component: UpdateLog,
      },
      {
        path: 'feeback',
        component: FeeBack,
      },
      {
        path: 'sponsor',
        component: Sponsor,
      },
      {
        path: 'update',
        component: UpdateProfile
      }
    ]
  }
]

checkStorage()

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    //这里判断用户是否登录，验证本地存储是否有token
    if (!store.state.token) { // 判断当前的token是否存在
      next({
        path: '/',
        query: {
          redirect: to.fullPath
        }
      })
      Vue.prototype.$notify({
        title: '登录失效',
        message: '请先登录',
        type: 'error',
        duration: 1500,
      })
    } else {
      next()
    }
  } else {
    next() // 确保一定要调用 next()
  }
})

export default router
