import store from '../store'
export default function checkStorage() {
  // 页面刷新时，重新赋值token
  if (window.localStorage.getItem('token')) {
    store.commit('save_token', window.localStorage.getItem('token'))
  }

  // 页面刷新时，重新赋值setting
  if (window.localStorage.getItem('setting')) {
    store.commit('save_setting', JSON.parse(window.localStorage.getItem('setting')))
  }
  // 页面刷新时，重新赋值userInfo
  if (window.localStorage.getItem('userInfo')) {
    store.commit('save_userInfo', JSON.parse(window.localStorage.getItem('userInfo')))
  }
  // 页面刷新时，重新赋值schedules
  if (window.localStorage.getItem('schedules')) {
    console.log('save_schedules')
    store.commit('save_schedules', JSON.parse(window.localStorage.getItem('schedules')))
  }
  // 页面刷新时，重新赋值arrayEvents
  if (window.localStorage.getItem('arrayEvents')) {
    console.log('save_arrayEvents')
    store.commit('save_arrayEvents', JSON.parse(window.localStorage.getItem('arrayEvents')))
  }
  // 页面刷新时，重新赋值power
  if (window.localStorage.getItem('power')) {
    console.log('save_power')
    store.commit('save_power', JSON.parse(window.localStorage.getItem('power')))
  }
}
