<template>
  <v-bottom-navigation v-if="isShow" class="bottom-bar" hide-on-scroll>
    <BottomBarItem path="/today" desc="今日" activeIco="mdi-white-balance-sunny" deActiveIco="mdi-weather-sunny"/>
    <BottomBarItem path="/profile" desc="我的" activeIco="mdi-account" deActiveIco="mdi-account-outline"/>
  </v-bottom-navigation>
</template>
<script>
import BottomBarItem from '../common/BottomBarItem'
export default {
  name: 'BottomBar',
  components: {
    BottomBarItem
  },
  computed: {
    isShow() {
      return this.$route.path === '/today' || this.$route.path === '/profile'
    }
  },
  created() {
  }
}
</script>

<style scoped>
.botton-bar {
  display: flex;
}
</style>
