import axios from 'axios'
import store from '../store'
import router from '../router/index'

export function request(config, timeout = 3000) {
  // 1.创建axios的实例
  const instance = axios.create({
    baseURL: 'https://napi.onemoreidea.cn/api_v1',
    timeout,
  })

  // 2.axios的拦截器
  instance.interceptors.request.use(
    config => {
      if (config.url !== '/auth/register' || config.url !== '/auth/access_token') {
        config.headers.Authorization = `Bearer ${store.state.token}`
      }
      return config
    },
    err => {
    // console.log(err);
    return Promise.reject(err.response)
  })

  // 2.2.响应拦截
  instance.interceptors.response.use(
    res => {
      return res.data
    },
    err => {
      if (err.response) {
        const status = err.response.status
        if (status === 401) {
          // 401 清除token信息并跳转到登录页面
          store.commit('clear_token')
          console.log('401清除token信息并跳转到登录页面')
          router.replace({
            path: '/login',
            query: { redirect: router.currentRoute.path },
          })
        }
      }
      // console.log(err)
      return Promise.reject(err.response)
    })

  // 3.发送真正的网络请求
  return instance(config)
}